module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WOOFCODE","short_name":"WOOFCODE","description":"A coding camp to teach web development literacy in just 10 weeks.","start_url":"/","background_color":"#ffffff","theme_color":"#333333","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6180d29f8a4aca9bf929abb72e0ee7df"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
